.no-underscore {
  text-decoration: none;
}

.word-wrap {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
      Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.vnc-avatar {
  position: relative;
  border-radius: 50%;
  font-size: 0.71rem;
  width: 24px;
  height: 24px;
  line-height: 24px;
  max-height: 24px;
  border: 1px solid #b4b4b4;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  background: grey;
  align-items: center;
  justify-content: center;
  display: flex;
}
.vnc-avatar .mat-icon.group-icon {
  color: #ababab;
  font-size: 14px;
  line-height: 18px;
  height: 14px;
  width: 14px;
}
.vnc-avatar .mat-icon.group-icon.hide {
  display: none;
}
.vnc-avatar img {
  max-height: 24px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  background: #999;
  -webkit-touch-callout: none;
  border-radius: 50%;
}
.vnc-avatar.incoming-group {
  border-radius: 0;
  width: 100%;
}
.vnc-avatar.incoming-group img {
  border-radius: 0;
  width: 100%;
  max-height: 100%;
}
.vnc-avatar.medium {
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.vnc-avatar .status.app-user-icon {
  font-size: 0.78rem;
  color: #888;
  background: white;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: -3px;
}
.vnc-avatar .avatar-text {
  color: black;
}
.vnc-avatar .avatar-text.hide {
  display: none;
}
.vnc-avatar.big {
  width: 58px;
  height: 58px;
  line-height: 58px;
  max-height: 58px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
}
.vnc-avatar.big .avatar-text {
  font-size: 16px;
}
.vnc-avatar.big img {
  min-width: 58px;
  max-width: 58px;
  max-height: 58px;
  height: 58px;
  width: 58px;
  background: #999;
  border-radius: 50%;
}
.vnc-avatar.participant {
  width: 24px;
  height: 24px;
  line-height: 24px;
  max-height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
}
.vnc-avatar.participant .avatar-text {
  font-size: 10px;
}
.vnc-avatar.participant img {
  min-width: 24px;
  max-width: 24px;
  max-height: 24px;
  height: 24px;
  width: 24px;
  background: #999;
  border-radius: 50%;
}
.vnc-avatar.x-big {
  width: 100px;
  height: 100px;
  line-height: 90px;
  max-height: 100px;
  font-size: 2.71rem;
}
.vnc-avatar.x-big .avatar-text {
  font-size: 3.21rem;
}
.vnc-avatar.x-big img {
  max-width: 100px;
  max-height: 100px;
  height: 100px;
  width: 100px;
  background: #999;
  border-radius: 50%;
}
.vnc-avatar.notification {
  width: 50px;
  height: 50px;
  line-height: 50px;
  max-height: 50px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vnc-avatar.notification .avatar-text {
  font-size: 1.28rem;
}
.vnc-avatar.notification.group-chat-icon {
  line-height: 44px;
}
.vnc-avatar.notification img {
  max-width: 50px;
  max-height: 50px;
  height: 50px;
  width: 50px;
  background: #999;
  border-radius: 50%;
}
.vnc-avatar.notification .status {
  bottom: 20px;
  right: -3px;
  width: 20px;
  height: 20px;
}
.vnc-avatar.user-account {
  border: 1px solid white;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 1rem;
}
.vnc-avatar.user-account img {
  height: 34px;
  width: 34px;
  max-height: 34px;
  margin-bottom: 5px;
}
.vnc-avatar.user-account .status {
  bottom: 0;
  right: 0;
}
.vnc-avatar .broadcast-svg-icon {
  width: 32px;
  height: 32px;
}
.vnc-avatar .broadcast-svg-icon path {
  fill: #ffffff;
}
.vnc-avatar.small {
  width: 47px;
  height: 47px;
  line-height: 47px;
  min-height: 47px;
  box-sizing: border-box;
}
.vnc-avatar.small img {
  width: 47px;
  height: 47px;
  line-height: 47px;
  min-height: 47px;
}
.vnc-avatar.small .status {
  width: 8px;
  height: 8px;
}
@media screen and (max-device-width: 800px) {
  .vnc-avatar.small .status.app-user-icon {
    font-size: 0.68rem;
    width: 14px;
    height: 14px;
  }
}
.vnc-avatar.small .status-blocked {
  color: red !important;
  font-size: 1rem;
  position: absolute;
  bottom: 3px;
  right: 0.8px;
  background-color: white;
  border-radius: 50%;
}
@media screen and (max-device-width: 800px) {
  .vnc-avatar.small .status-blocked {
    font-size: 0.9rem;
    right: -1px;
    bottom: -0.5px;
  }
}
.vnc-avatar.conference {
  width: 32px;
  height: 32px;
  line-height: 32px;
  min-height: 32px;
  box-sizing: border-box;
}
.vnc-avatar.conference img {
  height: 32px;
  width: 32px;
  max-height: 32px;
}
.vnc-avatar.profile {
  width: 46px;
  height: 46px;
  line-height: 46px;
  min-height: 46px;
  box-sizing: border-box;
}
.vnc-avatar.profile img {
  height: 46px;
  width: 46px;
  max-height: 46px;
}
.vnc-avatar.profile .status {
  width: 14px;
  height: 14px;
}
.vnc-avatar.chip-size {
  width: 28px;
  height: 28px;
  line-height: 26px;
  box-sizing: border-box;
}
.vnc-avatar.chip-size .status {
  width: 8px;
  height: 8px;
}
.vnc-avatar.chip-size .status-blocked {
  color: red;
  font-size: 1rem;
  position: absolute;
  bottom: 1px;
  right: -6px;
  background-color: white;
  border-radius: 50%;
}
.vnc-avatar.chip-size img {
  max-height: 26px;
  width: 26px;
}
.vnc-avatar.incomingCall {
  border: 2px solid white;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 1.28rem;
}
@media screen and (max-device-width: 800px) and (orientation: landscape) {
  .vnc-avatar.incomingCall {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .vnc-avatar.incomingCall img {
    width: 60px;
    height: 60px;
    max-height: 60px;
  }
}
.vnc-avatar.incomingCall img {
  max-height: 96px;
  height: 96px;
  width: 96px;
}
@media screen and (max-device-width: 800px) and (orientation: landscape) {
  .vnc-avatar.incomingCall img {
    width: 56px;
    height: 56px;
    max-height: 56px;
  }
}
.vnc-avatar.incomingGroupCall_botton {
  position: fixed;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 0%;
  border-top-left-radius: 0%;
  width: 164px;
  height: 82px;
  display: block;
  margin-top: 276px;
  margin-left: 126px;
  font-size: 2.14rem;
}
.vnc-avatar.incomingGroupCall_top {
  position: fixed;
  border-top-left-radius: 70%;
  border-top-right-radius: 70%;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  width: 164px;
  height: 82px;
  display: block;
  margin-top: 194px;
  margin-left: 126px;
  font-size: 2.14rem;
}
.vnc-avatar.incomingGroupCall_topleft {
  position: fixed;
  border-top-left-radius: 100%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  height: 82px;
  width: 82px;
  display: table-cell;
  margin-left: 126px;
  margin-top: 194px;
  font-size: 2.14rem;
  line-height: 90px;
  padding-left: 14px;
}
.vnc-avatar.incomingGroupCall_topright {
  position: fixed;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-left-radius: 0%;
  height: 82px;
  width: 82px;
  display: table-cell;
  margin-left: 208px;
  margin-top: 194px;
  font-size: 2.14rem;
  line-height: 90px;
  padding-right: 10px;
}
.vnc-avatar .status {
  position: absolute;
  bottom: 3px;
  right: -3px;
  width: 8px;
  height: 8px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  z-index: 0;
}
.vnc-avatar .status.online, .vnc-avatar .status.available, .vnc-avatar .status.xa, .vnc-avatar .status.chat {
  background-color: #00C853;
}
.vnc-avatar .status.offline, .vnc-avatar .status.unavailable, .vnc-avatar .status.error {
  background-color: #585858;
}
.vnc-avatar .status.busy {
  background-color: #FF0000;
}
.vnc-avatar .status.dnd {
  background-color: #D50000;
}
.vnc-avatar .status.away {
  background-color: #FF6D00;
}

tooltip-content > * {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0 5px;
  position: absolute;
  z-index: 9999;
}

.avatar .name {
  text-align: center;
  font-size: 10px;
}

.groupchat-avatar-icon {
  color: #ababab !important;
}

vp-header mat-toolbar {
  height: 61px;
  background-color: #317bbc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
vp-header mat-toolbar .header__left {
  display: flex;
  align-items: center;
}
vp-header mat-toolbar .header__left mat-icon {
  font-size: 30px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  color: white;
}
vp-header mat-toolbar .header__left .logo-title {
  margin-left: 15px;
  font-family: Montserrat;
  font-size: 24.2px;
  font-weight: 600;
  color: white;
}
vp-header mat-toolbar .header__left .logo-title .mcb {
  font-weight: normal;
}
vp-header mat-toolbar .header__center .search-form {
  position: relative;
}
vp-header mat-toolbar .header__center .search-form #searchInput {
  height: 38px;
  border-radius: 19px;
  background-color: rgba(255, 255, 255, 0.67);
  width: 430px;
  border: none;
  padding: 0 20px;
  outline: 0;
  padding-left: 30px;
}
vp-header mat-toolbar .header__center .search-form mat-icon {
  color: #575757;
  font-size: 20px;
  height: 20px;
  width: 20px;
  line-height: 20px;
}
vp-header mat-toolbar .header__center .search-form mat-icon.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
}
vp-header mat-toolbar .header__center .search-form mat-icon.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
vp-header mat-toolbar .header__right {
  display: flex;
  align-items: center;
}
vp-header mat-toolbar .header__right vp-avatar {
  border: 1px solid white;
  border-radius: 50%;
}
vp-header mat-toolbar .header__right .user-fullname {
  font-family: Manrope;
  font-size: 16px;
  color: white;
  margin-right: 20px;
}
vp-header .switch-holder {
  position: absolute;
  background: white;
  top: 48px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  z-index: 1001;
  left: 0;
  max-width: 400px;
  justify-content: start;
  padding: 12px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 16px 24px 0 rgba(7, 29, 51, 0.2), 0 12px 12px 0 rgba(7, 29, 51, 0.1);
}
vp-header .switch-holder .switch-box {
  position: relative;
  height: 132px;
  width: 120px;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
}
vp-header .switch-holder .switch-box:hover {
  background: #f5f8fa;
}
vp-header .switch-holder .switch-box:hover .switch-title {
  color: #000000;
}
vp-header .switch-holder .switch-box .switch-icon {
  position: relative;
  margin-top: 16px;
}
vp-header .switch-holder .switch-box .switch-icon mat-icon {
  padding: 10px;
  border-radius: 50%;
}
vp-header .switch-holder .switch-box .switch-icon img {
  width: 72px;
  display: block;
  margin: 0 auto;
}
vp-header .switch-holder .switch-box .switch-title {
  font-family: Manrope;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #606b76;
  text-align: center;
  margin-top: 8px;
}
vp-header vnc-top-bar .app-title {
  display: flex;
}
vp-header vnc-top-bar .vnc-top-bar__section--left {
  max-width: 400px;
  min-width: 400px;
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  vp-header vnc-top-bar .vnc-top-bar__section--left {
    max-width: 360px;
    min-width: 360px;
  }
}
@media screen and (min-width: 834px) and (max-width: 1023px) {
  vp-header vnc-top-bar .vnc-top-bar__section--left {
    max-width: 320px;
    min-width: 320px;
  }
}
@media screen and (min-width: 768px) and (max-width: 833px) {
  vp-header vnc-top-bar .vnc-top-bar__section--left {
    max-width: 280px;
    min-width: 280px;
  }
}
vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar {
  display: flex;
  justify-content: flex-end;
  max-width: calc(100% - 562px) !important;
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar {
    max-width: calc(100% - 540px) !important;
  }
}
@media screen and (min-width: 834px) and (max-width: 1023px) {
  vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar {
    max-width: calc(100% - 500px) !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 833px) {
  vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar {
    max-width: calc(100% - 460px) !important;
  }
}
vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar {
  background-color: var(--vnc-search-bar-bg-color, #285f8f);
}
vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar.vnc-search-bar--active {
  width: 100%;
}
vp-header vnc-top-bar vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar input.vnc-search-bar__input {
  font-family: "Source Sans Pro";
}
vp-header vnc-top-bar:not(.is-hin-theme):not(.is-ekbo-theme):not(.is-airbus-theme) vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar {
  background-color: #285f8f;
}
vp-header vnc-top-bar.is-hin-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar {
  --vnc-search-bar-bg-color: #f7f8fa !important;
  --vnc-search-bar-bg-color--active: #f7f8fa !important;
  --vnc-search-bar__bottom-line-color: $brand-blue-main !important;
  border: solid 1px #e3e7eb;
}
vp-header vnc-top-bar.is-hin-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar input.vnc-search-bar__input {
  color: #606b76 !important;
}
vp-header vnc-top-bar.is-hin-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar .vnc-search-bar__icon svg g {
  fill: #606b76 !important;
}
vp-header vnc-top-bar.is-hin-theme .vnc-top-bar__section--right svg g, vp-header vnc-top-bar.is-hin-theme .vnc-top-bar__section--left svg g, vp-header vnc-top-bar.is-hin-theme .app-title svg g {
  fill: #606b76 !important;
}
vp-header vnc-top-bar.is-ekbo-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar {
  --vnc-search-bar-bg-color: #4c1ea8 !important;
  --vnc-search-bar-bg-color--active: #4c1ea8 !important;
  --vnc-search-bar__bottom-line-color: #b233cc !important;
}
vp-header vnc-top-bar.is-ekbo-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar input.vnc-search-bar__input {
  color: white !important;
}
vp-header vnc-top-bar.is-ekbo-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar .vnc-search-bar__icon svg g, vp-header vnc-top-bar.is-ekbo-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar .clear-search-icon mat-icon {
  fill: white !important;
}
vp-header vnc-top-bar.is-airbus-theme .vnc-top-bar__apps-button {
  background-color: #063b9e;
}
vp-header vnc-top-bar.is-airbus-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar {
  --vnc-search-bar-bg-color: #063b9e !important;
  --vnc-search-bar-bg-color--active: #063b9e !important;
  --vnc-search-bar__bottom-line-color: #b233cc !important;
}
vp-header vnc-top-bar.is-airbus-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar input.vnc-search-bar__input {
  color: white !important;
}
vp-header vnc-top-bar.is-airbus-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar .vnc-search-bar__icon svg g, vp-header vnc-top-bar.is-airbus-theme vnc-search-bar.vnc-top-bar__search-bar .vnc-search-bar .clear-search-icon mat-icon {
  fill: white !important;
}
vp-header vnc-top-bar vnc-search-bar .vnc-search-bar__icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-sizing: content-box;
}
vp-header vnc-top-bar.is-ekbo-theme .vnc-top-bar, vp-header vnc-top-bar.is-hin-theme .vnc-top-bar, vp-header vnc-top-bar.is-airbus-theme .vnc-top-bar {
  --vnc-top-bar-bg-color--default: $brand-blue-main !important;
  border-bottom: 1px solid #e3e7eb;
}
vp-header vnc-top-bar.is-ekbo-theme .vnc-top-bar .vnc-top-bar__apps-button vnc-top-bar-apps-button, vp-header vnc-top-bar.is-hin-theme .vnc-top-bar .vnc-top-bar__apps-button vnc-top-bar-apps-button, vp-header vnc-top-bar.is-airbus-theme .vnc-top-bar .vnc-top-bar__apps-button vnc-top-bar-apps-button {
  --vnc-top-bar-apps-icon-bg-color--default: $brand-blue-main !important;
  --vnc-top-bar-apps-icon-bg-color-hovered--default: $brand-blue-main !important;
}
vp-header vp-header main-layout-header-row.is-hin-theme .app-title svg g {
  fill: #606b76 !important;
}
vp-header vp-header .is-airbus-theme .mobile-header-component .app-title {
  padding-left: 0;
}
vp-header vp-header .is-airbus-theme .mobile-header-component .app-title span {
  font-size: 20px;
}
vp-header .app-title {
  margin-left: 15px;
  font-family: Montserrat;
  font-size: 24.2px;
  font-weight: 600;
  font-family: "Montserrat";
  color: #ffffff;
}
vp-header .app-title .mcb {
  font-weight: normal;
}
vp-header .app-title.hide-logo {
  margin-left: 10px;
}
vp-header .app-title span:nth-child(1) {
  font-weight: 600;
  font-family: "Montserrat";
}
vp-header .app-title span:nth-child(2) {
  font-weight: 300;
  font-family: "Montserrat";
}

.header__right > button mat-icon {
  color: white;
}

.mcb_desktop_settings_menu .mat-menu-item {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #000;
}
.mcb_desktop_settings_menu mat-icon {
  color: #888;
  font-size: 18px;
  width: 18px;
  height: 18px;
}
.mcb_desktop_settings_menu a.menu-link {
  text-decoration: none;
}

.cdk-overlay-pane .mat-menu-panel.header-profile-menu {
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2), 0 4px 12px 0 rgba(8, 45, 61, 0.1);
  margin-top: 8px;
  min-width: 200px;
  transform: translate(10px, 8px);
}
.cdk-overlay-pane .mat-menu-panel.header-profile-menu .mat-menu-content .mat-menu-item {
  height: 36px;
  position: relative;
}
.cdk-overlay-pane .mat-menu-panel.header-profile-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.cdk-overlay-pane .mat-menu-panel.header-profile-menu span, .cdk-overlay-pane .mat-menu-panel.header-profile-menu a {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  color: #131313;
  display: flex;
  align-items: center;
}
.cdk-overlay-pane .mat-menu-panel.header-profile-menu span svg {
  margin-right: 8px;
}
.cdk-overlay-pane .mat-menu-panel.header-profile-menu svg {
  width: 18px;
  height: 18px;
}
.cdk-overlay-pane .mat-menu-panel.header-profile-menu svg path {
  fill: #8B96A0;
}

html,
body {
  font-family: "Manrope";
  font-size: 14px;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.meta-conference-board-component {
  height: calc(100vh - 62px);
  overflow-y: auto;
  background-color: #51575e;
  padding: 10px;
}
.meta-conference-board-component .mcb-content-wrapper.is-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meta-conference-board-component .mcb-content-wrapper .no-results {
  text-align: center;
  font-size: 28px;
  color: white;
}
.meta-conference-board-component .mcb-item {
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.meta-conference-board-component .mcb-item .mcb-item__header {
  display: flex;
  align-items: center;
  font-family: Manrope;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
}
.meta-conference-board-component .mcb-item .mcb-item__header .mcb-item__header__center {
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 40px;
}
.meta-conference-board-component .mcb-item .mcb-item__header .mcb-item__header__right mat-icon {
  font-size: 20px;
  height: 20px;
  line-height: 20px;
}
.meta-conference-board-component .mcb-item .mcb-item__description {
  color: #888;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 40px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.42;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.meta-conference-board-component .mcb-item .mcb-item__stats {
  height: 144px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.meta-conference-board-component .mcb-item .mcb-item__stats h5 {
  font-family: Manrope;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 10px;
}
.meta-conference-board-component .mcb-item .stats-boxes {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}
.meta-conference-board-component .mcb-item .stats-boxes .stats-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.meta-conference-board-component .mcb-item .stats-boxes .stats-box mat-icon {
  font-size: 36px;
  height: 36px;
  line-height: 36px;
  width: 36px;
  margin-bottom: 10px;
}
.meta-conference-board-component .mcb-item .stats-boxes .stats-box mat-icon.archived {
  color: #fc8924;
}
.meta-conference-board-component .mcb-item .stats-boxes .stats-box mat-icon.ended {
  color: #e02b17;
}
.meta-conference-board-component .mcb-item .stats-boxes .stats-box .stat-icon {
  position: relative;
}
.meta-conference-board-component .mcb-item .stats-boxes .stats-box .stat-icon mat-icon.live-icon {
  width: 11px;
  height: 11px;
  color: #31b51f;
  position: absolute;
  font-size: 11px;
  line-height: 11px;
  border-radius: 50%;
  left: 0;
  bottom: 5px;
}

.meta-conference-board-detail-component {
  background-color: #51575e;
}
.meta-conference-board-detail-component .mcb-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: rgba(40, 43, 47, 0.8);
  font-size: 18px;
  font-family: "Manrope";
  color: #FFFFFF;
  padding: 0 15px;
}
.meta-conference-board-detail-component .mcb-header__left button {
  width: 24px;
}
.meta-conference-board-detail-component .mcb-header__left button mat-icon {
  font-size: 24px;
}
.meta-conference-board-detail-component .mcb-header__center {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  font-family: Manrope;
}
.meta-conference-board-detail-component .mcb-header__center mat-icon {
  font-size: 24px;
  height: 24px;
  line-height: 24px;
}
.meta-conference-board-detail-component .mcb-header__left, .meta-conference-board-detail-component .mcb-header__right {
  width: 200px;
}
.meta-conference-board-detail-component .mcb-header__right {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.meta-conference-board-detail-component .mcb-header__right mat-icon {
  font-size: 24px;
  line-height: 24px;
  margin-left: 5px;
}
.meta-conference-board-detail-component .mcb-header__right span.total-live {
  width: 20.5px;
  height: 20.5px;
  background-color: #29b6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}
.meta-conference-board-detail-component .mcb-header__right .navigation {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.meta-conference-board-detail-component .mcb-header__right .navigation span.page-info {
  font-family: Manrope;
  font-size: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.meta-conference-board-detail-component .mcb-header__right .navigation mat-icon {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.meta-conference-board-detail-component .mcb-header__right .navigation mat-icon.disabled {
  color: #51575e;
}
.meta-conference-board-detail-component .mcb-content-wrapper {
  height: calc(100vh - 50px);
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
}
.meta-conference-board-detail-component .mcb-content-wrapper:not(.is-sidebar-expanded) {
  padding: 0;
}
.meta-conference-board-detail-component .mcb-content-wrapper.show-fullscreen {
  padding: 0;
}
.meta-conference-board-detail-component .mcb-content-wrapper.show-fullscreen vp-conference-tile {
  padding: 5px;
}
.meta-conference-board-detail-component .mcb-content-wrapper .mat-progress-spinner {
  margin: 0 auto;
}
.meta-conference-board-detail-component .mcb-content-wrapper .mat-grid-list {
  height: 100% !important;
  width: 100%;
}
.meta-conference-board-detail-component .mcb-content-wrapper.is-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mcbAction {
  width: 52px;
  height: 52px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  min-width: 52px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}
#mcbAction mat-icon {
  font-size: 28px;
  height: 28px;
  line-height: 28px;
  color: #29b6f6;
  width: 28px;
}

.tag-item {
  height: 18px;
  border-radius: 3px;
  background-color: #e5e5e5;
  margin-top: 2px;
  float: left;
  margin-right: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.tag-item .tag-name {
  font-family: "Source Sans Pro";
  font-size: 11px;
  line-height: 18px;
  max-width: 85px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tag-item mat-icon {
  width: 12px;
  font-size: 12px;
  cursor: pointer;
  height: 12px;
  line-height: 12px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}
.form-group.no-margin {
  margin: 0;
}
.form-group input {
  border: none;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid #e1e1e1;
  height: 24px;
  color: #000;
}
.form-group input.mat-autocomplete-trigger {
  border: none;
}
.form-group #searchControl {
  width: 100%;
}
.form-group .search-control {
  position: relative;
}
.form-group .search-control mat-icon {
  position: absolute;
  right: 0;
  color: #888888;
  font-size: 20px;
}
.form-group .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #e1e1e1;
  bottom: 1.55em;
}
.form-group input::-webkit-input-placeholder,
.form-group textarea::-webkit-input-placeholder {
  color: #888888;
}
.form-group input:-moz-placeholder,
.form-group textarea:-moz-placeholder {
  color: #888888;
}
.form-group input::-moz-placeholder,
.form-group textarea::-moz-placeholder {
  color: #888888;
}
.form-group input:-ms-input-placeholder,
.form-group textarea:-ms-input-placeholder {
  color: #888888;
}
.form-group .form-label {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}
.form-group .form-label + p {
  margin: 0;
}
.form-group textarea {
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  resize: none;
  border-radius: 0;
  outline: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.edit-conference-dialog .mat-dialog .mat-dialog__body {
  padding: 0 15px;
}
.edit-conference-dialog .conference-avatar {
  height: 58px;
  width: 58px;
  margin-right: 20px;
  cursor: pointer;
}
.edit-conference-dialog input,
.edit-conference-dialog textarea {
  font-family: "Source Sans Pro";
  font-size: 16px;
}
.edit-conference-dialog .form-group .form-label {
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 0;
}
.edit-conference-dialog .conference-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.edit-conference-dialog .conference-info vp-avatar {
  margin-right: 10px;
}
.edit-conference-dialog .conference-info .conference-name {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.selected-conferences {
  min-width: 100%;
  overflow-x: auto;
  height: 90px;
  white-space: nowrap;
  display: flex;
}
.selected-conferences .selected-conference-item {
  display: inline-block;
}
.selected-conferences .selected-conference-item .conference-avatar {
  margin-right: 20px;
  position: relative;
}
.selected-conferences .selected-conference-item .conference-avatar mat-icon.mdi-close {
  position: absolute;
  bottom: 0;
  right: -6px;
  width: 18px;
  line-height: 14px;
  font-size: 14px;
  cursor: pointer;
  height: 18px;
  color: white;
  border-radius: 50%;
  border: solid 2px #ffffff;
  background-color: #888888;
}
.selected-conferences .selected-conference-item .conference-title {
  width: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conferences-list {
  overflow-y: auto;
  overflow-x: hidden;
}
.conferences-list .conference-heading {
  color: white;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.conferences-list .conference-heading mat-icon {
  font-size: 16px;
  margin-right: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
}
.conferences-list .conference-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 5px;
}
.conferences-list .conference-item .conference-avatar {
  margin-right: 20px;
}
.conferences-list .conference-item .conference-avatar .vnc-avatar.small {
  width: 32px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
}
.conferences-list .conference-item .conference-avatar .vnc-avatar.small img {
  width: 32px;
  height: 32px;
}
.conferences-list .conference-item .conference-info {
  width: 440px;
}
.conferences-list .conference-item .conference-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}
.conferences-list .conference-item .conference-description {
  font-size: 12px;
  color: #888;
}

.mcb-form-panel .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
}

.cdk-overlay-backdrop.mcb-form-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.mat-dialog {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.mat-dialog .mat-dialog__header {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 20px;
}
.mat-dialog .mat-dialog__header.no-border {
  border: none;
}
.mat-dialog .mat-dialog__header h2 {
  font-family: Manrope;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mat-dialog .mat-dialog__header mat-icon {
  color: #888;
  font-size: 20px;
  cursor: pointer;
}
.mat-dialog .mat-dialog__body {
  height: calc(100% - 100px);
  padding: 0 20px;
  overflow-y: auto;
}
.mat-dialog .mat-dialog__body .conferences-list {
  max-height: calc(100% - 220px);
}
.mat-dialog .mat-dialog__body .conferences-list cdk-virtual-scroll-viewport {
  height: 380px;
}
.mat-dialog .mat-dialog__body .conferences-list.has-selected-conferences {
  max-height: 190px;
}
.mat-dialog .mat-dialog__body .conferences-list.has-selected-conferences cdk-virtual-scroll-viewport {
  height: 190px;
}
.mat-dialog .mat-dialog__body .conferences-list .conference-item {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.mat-dialog .mat-dialog__footer {
  height: 48px;
  padding: 0 20px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: flex-end;
}
.mat-dialog .mat-dialog__footer.no-border {
  border: none;
}
.mat-dialog .mat-dialog__footer button {
  color: #317bbc;
  font-size: 15px;
  font-family: Manrope;
  font-weight: bold;
}

.current-list {
  overflow-y: auto;
  height: 52px;
}

.mcb-name {
  height: 17px;
  border-radius: 3px;
  background-color: #e5e5e5;
  float: left;
  font-family: "Source Sans Pro";
  font-size: 11px;
  padding: 0 5px;
  line-height: 17px;
  word-break: break-word;
  margin-right: 10px;
  margin-top: 5px;
}
.mcb-name mat-icon {
  cursor: pointer;
}

.change-mcb-list-form > label {
  width: 100%;
  display: block;
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
}

.mat-form-field {
  width: 100%;
  margin-top: 20px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
  background: transparent;
  border-radius: 0;
  padding: 0;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  padding-bottom: 5px;
}

.conferences-list-component {
  width: 290px;
  min-height: 105px;
  opacity: 0.8;
  border-radius: 6px;
  background-color: #000000;
  position: fixed;
  top: 40px;
  right: 10px;
  z-index: 999;
  padding: 10px 15px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.conferences-list-component .conferences-list {
  margin-bottom: 10px;
}
.conferences-list-component .conferences-list .conference-title {
  color: white;
}
.conferences-list-component .filter-form {
  margin-top: 10px;
}
.conferences-list-component .filter-form .form-group .search-control mat-icon {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 16px;
}
.conferences-list-component #conferenceSearchControl {
  background: transparent;
  width: 100%;
  border-bottom: 1px solid #51575e;
  color: white;
}
.conferences-list-component input::-webkit-input-placeholder,
.conferences-list-component textarea::-webkit-input-placeholder {
  color: #888888;
}
.conferences-list-component input:-moz-placeholder,
.conferences-list-component textarea:-moz-placeholder {
  color: #888888;
}
.conferences-list-component input::-moz-placeholder,
.conferences-list-component textarea::-moz-placeholder {
  color: #888888;
}
.conferences-list-component input:-ms-input-placeholder,
.conferences-list-component textarea:-ms-input-placeholder {
  color: #888888;
}
.conferences-list-component .conferences-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: Manrope;
  font-size: 18px;
  font-weight: bold;
}
.conferences-list-component .conferences-list-header button {
  width: 32px;
  height: 32px;
  background-color: #29b6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.conferences-list-component .conferences-list-header button mat-icon {
  font-size: 18px;
}

.form-group.password-form {
  width: 200px;
}
.form-group.password-form mat-icon.toggle-password {
  color: #888888;
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 16px;
  width: 16px;
  cursor: pointer;
}

.no-conferences-wrapper {
  text-align: center;
  width: 100%;
}
.no-conferences-wrapper button {
  width: 52px;
  height: 52px;
  background: white;
  margin-top: 30px;
}
.no-conferences-wrapper mat-icon {
  font-size: 28px;
  line-height: 28px;
  color: #29b6f6;
  width: 28px;
}
.no-conferences-wrapper .no-conferences-message {
  font-family: "Source Sans Pro";
  font-size: 36px;
  color: #b2b2b2;
}
.no-conferences-wrapper .start-conference {
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.mat-dialog.mcb-form-dialog.start-conference .reset-startime {
  position: absolute;
  right: -2px;
  font-size: 18px;
  cursor: pointer;
}
.mat-dialog.mcb-form-dialog.start-conference span.total-participants {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 300;
  color: #23b5f9;
  margin-left: 10px;
}
.mat-dialog.mcb-form-dialog.start-conference .edit-participants {
  position: absolute;
  right: 0;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #23b5f9;
  cursor: pointer;
}
.mat-dialog.mcb-form-dialog.start-conference .conference-info-group {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.mat-dialog.mcb-form-dialog.start-conference .conference-avatar {
  width: 58px;
  height: 58px;
  background-color: #eaeaea;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 58px;
}
.mat-dialog.mcb-form-dialog.start-conference .conference-avatar mat-icon {
  color: #a8a8a8;
  font-size: 35px;
  height: 35px;
  line-height: 35px;
  width: 35px;
}
.mat-dialog.mcb-form-dialog.start-conference .mat-form-field {
  margin-top: 0;
}
.mat-dialog.mcb-form-dialog.start-conference .mat-form-field .datetime-picker-input {
  border: none;
}
.mat-dialog.mcb-form-dialog.start-conference .conference-name {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
}
.mat-dialog.mcb-form-dialog.start-conference .floating-icon {
  float: right;
  color: #23b5f9;
  font-size: 24px;
  cursor: pointer;
}
.mat-dialog.mcb-form-dialog.start-conference .form-label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.selected-tags {
  width: 100%;
}

mat-datepicker-toggle.mat-datepicker-toggle {
  float: right;
  width: 24px;
}
mat-datepicker-toggle.mat-datepicker-toggle button {
  height: 24px;
  line-height: 24px;
  width: 24px;
}
mat-datepicker-toggle.mat-datepicker-toggle svg {
  fill: #23b5f9;
}

.select-participants .participant-roles {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.select-participants .participant-role {
  height: 30px;
  border-radius: 4px;
  border: solid 1px #e2e2e2;
  display: flex;
  align-items: center;
  font-family: "Source Sans Pro";
  font-size: 12px;
  padding: 0 10px;
  margin-right: 15px;
  color: #000;
  cursor: pointer;
}
.select-participants .participant-role.selected {
  background: #e2e2e2;
}
.select-participants .participant-role span {
  height: 16px;
}
.select-participants .participant-role mat-icon {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #888888;
}
.select-participants .participant-role mat-icon.moderator-icon {
  color: #fea929;
}
.select-participants .participant-role mat-icon.participant-icon {
  color: #28b3f2;
}
.select-participants .participant-desc {
  margin-top: 10px;
  color: #000;
}

.selected-participants {
  display: flex;
  overflow-x: auto;
}
.selected-participants .participant-item {
  width: 65px;
  position: relative;
  font-family: "Source Sans Pro";
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selected-participants .participant-item .participant-avatar {
  position: relative;
}
.selected-participants .participant-item .participant-avatar mat-icon {
  width: 16.1px;
  height: 16.1px;
  border: solid 2px #ffffff;
  background-color: #888888;
  color: white;
  border-radius: 50%;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  right: -8px;
  bottom: 0;
  cursor: pointer;
}
.selected-participants .participant-item .participant-info {
  display: flex;
  align-items: center;
}
.selected-participants .participant-item .participant-info mat-icon {
  line-height: 24px;
  margin-left: 3px;
  color: #888888;
}
.selected-participants .participant-item .participant-info mat-icon.moderator-icon {
  color: #fea929;
}
.selected-participants .participant-item .participant-info mat-icon.participant-icon {
  color: #28b3f2;
}
.selected-participants .participant-item .participant-info .participant-name {
  max-width: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#custom-input {
  position: absolute;
  width: 75px;
  height: 35px;
  opacity: 0;
}

.conference-avatar img {
  border-radius: 50%;
  width: 58px;
}

.all-participants {
  max-height: calc(100% - 176px);
  overflow-y: auto;
}
.all-participants.has-selected-participants {
  max-height: calc(100% - 233px);
}
.all-participants .participant-item {
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  padding: 10px 0;
  cursor: pointer;
}
.all-participants .participant-item .participant-avatar {
  margin-right: 20px;
}
.all-participants .participant-item .participant-name {
  max-width: calc(100% - 80px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Source Sans Pro";
  font-size: 14px;
}
.all-participants .participant-item .participant-name mat-icon {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #888888;
}
.all-participants .participant-item .participant-name mat-icon.moderator-icon {
  color: #fea929;
}
.all-participants .participant-item .participant-name mat-icon.participant-icon {
  color: #28b3f2;
}

.commander__dialog {
  position: fixed !important;
  top: 64px;
  left: 0;
}
.commander__dialog .mat-dialog-container {
  border-radius: 0;
}

.apps-list {
  width: 530px;
  height: 260px;
  background-color: #ffffff;
}
.apps-list .app-item {
  width: 130px;
  height: 130px;
  float: left;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.apps-list .app-item img {
  width: 53px;
}
.apps-list .app-item:hover {
  background-color: #f0f0f0;
}

.conferences-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vjs-modal-dialog-content {
  display: none;
}

.video-error {
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  display: FLEX;
  text-align: center;
  align-items: center;
  height: 100%;
  justify-content: center;
}

vp-sidebar {
  min-width: 295px;
  height: calc(100vh - 50px);
  margin-left: 10px;
  color: white;
  position: fixed;
  right: -280px;
}
vp-sidebar.is-sidebar-expanded {
  position: relative;
  right: auto;
}
vp-sidebar #toggleSidebarBtn {
  width: 20px;
  height: 20px;
  border: solid 1px #51575e;
  background-color: #41464b;
  position: absolute;
  left: -10px;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
vp-sidebar .sidebar-wrapper {
  position: fixed;
  width: 295px;
  height: calc(100vh - 56px);
}
vp-sidebar #startConferenceBtn {
  width: 52px;
  height: 52px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
vp-sidebar #startConferenceBtn mat-icon {
  color: #29b6f6;
  font-size: 35px;
  width: 35px;
  height: 35px;
  line-height: 35px !important;
}
vp-sidebar #startConferenceBtn.is-collapsed {
  bottom: 20px;
  position: fixed;
  right: 10px;
  margin: 0;
}
vp-sidebar .start-conference-button {
  height: 77px;
  background-color: #29b6f6;
  border-radius: 0 0 5px 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
vp-sidebar .start-conference-button .start-conference {
  font-family: Manrope;
  font-size: 14px;
}

.mcb__dialog-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.version_dialog {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: relative;
}
.version_dialog .logo-title {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 600;
}
.version_dialog .content {
  text-align: center;
}
.version_dialog svg {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.version_dialog .app-desc {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
}
.version_dialog span.app_version.disable-select {
  font-family: "Montserrat";
  font-size: 14px;
}
.version_dialog #mobile_sidebar_about_version_changelog {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.56;
  letter-spacing: normal;
  text-align: center;
}
.version_dialog #mobile_sidebar_about_version_changelog a {
  text-decoration: none;
  color: #29b6f6;
}
.version_dialog h6.app_version_name {
  margin: 0;
}
.version_dialog .submit {
  display: flex;
  justify-content: flex-end;
  color: white;
}

.mat-dialog.help-dialog-component h6 {
  font-family: Manrope;
  font-size: 16px;
  margin: 0;
}
.mat-dialog.help-dialog-component .link-option {
  margin-bottom: 40px;
}
.mat-dialog.help-dialog-component .link-option a {
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: #317bbc;
  text-decoration: none;
}

.service-desk-dialog-component .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-desk-dialog-component a#visitVncLink {
  font-family: Manrope;
  font-size: 15px;
  font-weight: bold;
  color: #317bbc;
  margin-top: 10px;
  text-decoration: none;
}
.service-desk-dialog-component .mat-dialog__body {
  font-family: "Source Sans Pro";
  font-size: 16px;
  height: calc(100% - 50px);
}

.mat-menu-panel.participant-kebab-menu {
  max-width: 400px;
}

vp-conference-tile {
  height: 100%;
  width: 100%;
}
vp-conference-tile .conference-tile-component {
  height: 100%;
  width: 100%;
  background: black;
  display: flex;
  font-size: 14px;
  letter-spacing: 0px;
  color: #FFFFFF;
  flex-direction: column;
}
vp-conference-tile .conference-tile-component .conference-tile-header {
  height: 35px;
  background: #282B2F;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
vp-conference-tile .conference-tile-component .conference-tile-header__center {
  width: calc(100% - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
}
vp-conference-tile .conference-tile-component .conference-tile-header__right {
  display: flex;
  align-items: center;
}
vp-conference-tile .conference-tile-component .conference-tile-header .mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: transparent;
}
vp-conference-tile .conference-tile-component .conference-tile-header mat-icon {
  color: white;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
}
vp-conference-tile .conference-tile-component section.conference-tile-body {
  height: calc(100% - 70px);
  overflow: hidden;
}
vp-conference-tile .conference-tile-component section.conference-tile-body.hide-header {
  height: calc(100% - 35px);
}
vp-conference-tile .conference-tile-component section.conference-tile-body.is-scheduled {
  height: calc(100% - 106px);
}
vp-conference-tile .conference-tile-component section.conference-tile-body.is-scheduled.hide-header {
  height: calc(100% - 71px);
}
vp-conference-tile .conference-tile-component section.conference-tile-body .video-container {
  height: 100%;
  width: 100%;
}
vp-conference-tile .conference-tile-component section.conference-tile-body video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .video-js, vp-conference-tile .conference-tile-component section.conference-tile-body .vjs_video_694-dimensions {
  width: 100%;
  height: 100%;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view {
  display: flex;
  height: 100%;
  background: #41464b;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  overflow-y: auto;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .timer-icon mat-icon {
  color: #00ffff;
  font-size: 40px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-link-wrapper {
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-link {
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: #29b6f6;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-middle {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-link-wrapper mat-icon {
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  height: 16px;
  line-height: 16px;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-description {
  margin-bottom: 10px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 40px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.42;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  width: 100%;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view p.starts-in {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  color: #888888;
  margin: 5px 0;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-timer {
  font-family: Manrope;
  font-size: 20px;
  width: 200px;
  text-align: center;
}
vp-conference-tile .conference-tile-component section.conference-tile-body .scheduled-conference-view .conference-timer .day-left {
  font-size: 18px;
  text-align: center;
  height: 25px;
}
vp-conference-tile .conference-tile-component .conference-tile-footer {
  height: 35px;
  background: #282B2F;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
vp-conference-tile .conference-tile-component .conference-tile-footer .conference-tile-footer__left {
  display: flex;
  align-items: center;
}
vp-conference-tile .conference-tile-component .conference-tile-footer.is-scheduled {
  height: 70px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5px;
  justify-content: flex-start;
}
vp-conference-tile .conference-tile-component .conference-tile-footer .invite-label {
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}
vp-conference-tile .conference-tile-component .conference-tile-footer .invited-participants {
  height: 40px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 40px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.42;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
vp-conference-tile .conference-tile-component .conference-tile-footer mat-icon {
  width: 18px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  margin-right: 5px;
}
vp-conference-tile .conference-tile-component .conference-tile-footer span.raise-hand-icon.raise_hand_participantVideo {
  margin-right: 5px;
}
vp-conference-tile .conference-tile-component .conference-tile-footer span.total-number {
  font-size: 14px;
  font-family: "Source Sans Pro";
  color: #FFFFFF;
  margin-right: 20px;
}

vp-conference-tile-filter {
  width: 100%;
  height: 100%;
}
vp-conference-tile-filter .conference-tile-filter-component {
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100% - 85px);
  flex-direction: column;
  padding: 0 15px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-top: 5px;
  border-radius: 5px 5px 0 0;
}
vp-conference-tile-filter .conference-tile-filter-component.is-collapsed {
  border-radius: 5px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter {
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 1;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab {
  cursor: pointer;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab span.total-conferences {
  width: 21px;
  height: 21px;
  background: #29B6F6 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: -15px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab .conference-tab-icon {
  position: relative;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab.ended-conference-tab mat-icon {
  color: #E02B17;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab.archived-conference-tab mat-icon {
  color: #FC8924;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab.running-conference-tab mat-icon {
  color: #09c856;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab .conference-tab-icon mat-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab .conference-tab-name {
  font-family: Manrope;
  font-size: 10px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter .conference-tab .conference-tabname {
  font-size: 16px;
  padding: 0 10px;
  line-height: 20px;
  text-align: center;
  margin-top: 5px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter > div {
  color: white;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #888888;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter > div:hover, vp-conference-tile-filter .conference-tile-filter-component .conference-filter > div.active {
  border-color: #29b6f6;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-filter > div:hover .conference-tab-name, vp-conference-tile-filter .conference-tile-filter-component .conference-filter > div.active .conference-tab-name {
  font-weight: bold;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content {
  overflow-y: auto;
  height: calc(100% - 70px);
  width: 100%;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item {
  height: 38px;
  display: flex;
  margin-bottom: 15px;
  margin-top: 10px;
  cursor: pointer;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item .conference-avatar {
  background: black;
  border-radius: 50%;
  margin-right: 15px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item .conference-info {
  display: flex;
  align-items: center;
  max-width: calc(100% - 48px);
  flex-direction: row;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item .conference-info .conference-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-weight: bold;
  font-size: 12px;
  width: calc(100% - 10px);
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item .conference-info .mat-icon-button {
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin-right: 5px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item .conference-info .mat-icon-button mat-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
}
vp-conference-tile-filter .conference-tile-filter-component .conference-content .conference-item .conference-info .conference-description {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.2);
}

.participants-list {
  width: 231px;
  max-height: 447px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px;
  overflow-y: auto;
  position: fixed;
  z-index: 999;
}
.participants-list .participant-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.participants-list .participant-item .participant-name {
  color: black;
  margin-left: 10px;
  font-family: "Source Sans Pro";
  font-size: 14px;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.participants-list .participant-item vp-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
}